// jshint ignore: start
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const MikeButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    textTransform: 'none',
      '&:hover': {
        backgroundColor: '#007bff',
        borderColor: '#007bff',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0069d9',
        borderColor: '#0069d9',
      },
  },
  disabled: {
    transition: 'none',
    borderColor: '1px solid rgba(0, 0, 0, 0.26)',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    '&:hover': {
      borderColor: '1px solid rgba(0, 0, 0, 0.26)',
      border: '1px solid rgba(0, 0, 0, 0.26)',
    }
  }
})(Button);

const MyButton = (props) => {
    return <MikeButton variant="contained" color="primary" disableElevation {...props} />;
}

export default MyButton;