import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const HomeCoverBig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        homecoverlittle: file(relativePath: { eq: "home-coverLittle.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
     <GatsbyImage
        style={{
          height: 710
        }}
        layout="fullWidth"
        aspectatio={3 / 1}
        alt="Cover"
        blurred
        imgStyle={{height: 'initial', minHeight: 710}}
        image={data.homecoverlittle.childImageSharp.gatsbyImageData}
        formats={["auto", "webp", "avif"]}
      />
  )
}

export default HomeCoverBig