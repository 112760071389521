import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/';
import { GatsbyImage } from "gatsby-plugin-image";
import MyButton from '../../tools/MyButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';

const useStyles = makeStyles((theme) => ({
    container: {
      width: '100%',
      margin: '20px 0',
      position: 'relative',
      display: 'table'
    },
    tableCell: {
      position: 'relative',
      display: 'table-cell',
      verticalAlign: 'middle',
      padding: '55px 0',
      zIndex: 2
    },
    tableMargin: {
      paddingLeft: 55,
      paddingRight: 55,
      textAlign: 'center'
    },
    tableMargin1: {
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'center'
    },
    title: {
      textShadow: '0 0 4px rgb(0 0 0 / 40%)',
      color: 'white',
      marginBottom: 8
    },
    subtitle: {
      textShadow: '0 0 4px rgb(0 0 0 / 40%)',
      color: 'white',
      marginBottom: 8,
      maxWidth: '75%',
      margin: '0 auto'
    },
    shadow: {
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        display: "grid",
        background: '#000'
    }
}));

const FeatureSection = ({image, title, subtitle, height, opacity, showButton, titleButton, linkButton }) => {
  const classes = useStyles()
  const isDesktop = useMediaQuery(
    json2mq({
      maxWidth: 750,
    })
  );

  const handleClick = () => {
    navigate(linkButton)
  }

  return (
      <div style={{ display: "grid" }}>
     <GatsbyImage
        style={{
        gridArea: "1/1",
          width: '100%',
          height: height
        }}
        layout="fullWidth"
        aspectatio={3 / 1}
        alt="Cover"
        image={image}
        formats={["auto", "webp", "avif"]}
      />
      <div className={classes.shadow} style={{opacity: opacity}}></div>
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
    <div className={isDesktop ? classes.tableMargin1 : classes.tableMargin}>
        <Typography style={isDesktop ? {fontSize: 34} : {fontSize: 54}} className={classes.title} variant="h6" component="h2">{title}</Typography>
        <Typography style={isDesktop ? {fontSize: 18} : {fontSize: 28}} className={classes.subtitle} variant="h6" component="h2">{subtitle}</Typography>
        { showButton && <MyButton onClick={handleClick} style={{minWidth: 100, marginTop: 27}}>{titleButton}</MyButton>}
      </div>
      </div>
   
    </div>
  )
}

export default FeatureSection